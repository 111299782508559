import { AbiItem } from '@jdiyield/interfaces'

type Props = {
  pendingReward?: string
  rewardPerBlock?: string
}

export const miniChefGenABI = (nameFunctions: Props): AbiItem[] => {
  const pendingReward = nameFunctions?.pendingReward || 'pendingReward'
  const rewardPerBlock = nameFunctions?.rewardPerBlock || 'rewardPerBlock'
  try {
    return [
      {
        inputs: [{ internalType: 'contract IERC20', name: '_sushi', type: 'address' }],
        stateMutability: 'nonpayable',
        type: 'constructor'
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: 'address', name: 'user', type: 'address' },
          { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
          { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
          { indexed: true, internalType: 'address', name: 'to', type: 'address' }
        ],
        name: 'Deposit',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: 'address', name: 'user', type: 'address' },
          { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
          { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
          { indexed: true, internalType: 'address', name: 'to', type: 'address' }
        ],
        name: 'EmergencyWithdraw',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: 'address', name: 'user', type: 'address' },
          { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
          { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' }
        ],
        name: 'Harvest',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
          { indexed: false, internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
          { indexed: true, internalType: 'contract IERC20', name: 'lpToken', type: 'address' },
          { indexed: true, internalType: 'contract IRewarder', name: 'rewarder', type: 'address' }
        ],
        name: 'LogPoolAddition',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
          { indexed: false, internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
          { indexed: true, internalType: 'contract IRewarder', name: 'rewarder', type: 'address' },
          { indexed: false, internalType: 'bool', name: 'overwrite', type: 'bool' }
        ],
        name: 'LogSetPool',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [{ indexed: false, internalType: 'uint256', name: 'sushiPerSecond', type: 'uint256' }],
        name: 'LogSushiPerSecond',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
          { indexed: false, internalType: 'uint64', name: 'lastRewardTime', type: 'uint64' },
          { indexed: false, internalType: 'uint256', name: 'lpSupply', type: 'uint256' },
          { indexed: false, internalType: 'uint256', name: 'accSushiPerShare', type: 'uint256' }
        ],
        name: 'LogUpdatePool',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
          { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' }
        ],
        name: 'OwnershipTransferred',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: 'address', name: 'user', type: 'address' },
          { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
          { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
          { indexed: true, internalType: 'address', name: 'to', type: 'address' }
        ],
        name: 'Withdraw',
        type: 'event'
      },
      {
        inputs: [],
        name: 'SUSHI',
        outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'allocPoint', type: 'uint256' },
          { internalType: 'contract IERC20', name: '_lpToken', type: 'address' },
          { internalType: 'contract IRewarder', name: '_rewarder', type: 'address' }
        ],
        name: 'add',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'bytes[]', name: 'calls', type: 'bytes[]' },
          { internalType: 'bool', name: 'revertOnFail', type: 'bool' }
        ],
        name: 'batch',
        outputs: [
          { internalType: 'bool[]', name: 'successes', type: 'bool[]' },
          { internalType: 'bytes[]', name: 'results', type: 'bytes[]' }
        ],
        stateMutability: 'payable',
        type: 'function'
      },
      { inputs: [], name: 'claimOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
      {
        inputs: [
          { internalType: 'uint256', name: 'pid', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'address', name: 'to', type: 'address' }
        ],
        name: 'deposit',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'pid', type: 'uint256' },
          { internalType: 'address', name: 'to', type: 'address' }
        ],
        name: 'emergencyWithdraw',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'pid', type: 'uint256' },
          { internalType: 'address', name: 'to', type: 'address' }
        ],
        name: 'harvest',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'lpToken',
        outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256[]', name: 'pids', type: 'uint256[]' }],
        name: 'massUpdatePools',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: '_pid', type: 'uint256' }],
        name: 'migrate',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'migrator',
        outputs: [{ internalType: 'contract IMigratorChef', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'pendingOwner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: '_pid', type: 'uint256' },
          { internalType: 'address', name: '_user', type: 'address' }
        ],
        name: pendingReward,
        outputs: [{ internalType: 'uint256', name: 'pending', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'contract IERC20', name: 'token', type: 'address' },
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' }
        ],
        name: 'permitToken',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'poolInfo',
        outputs: [
          { internalType: 'uint128', name: 'accSushiPerShare', type: 'uint128' },
          { internalType: 'uint64', name: 'lastRewardTime', type: 'uint64' },
          { internalType: 'uint64', name: 'allocPoint', type: 'uint64' }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'poolLength',
        outputs: [{ internalType: 'uint256', name: 'pools', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'rewarder',
        outputs: [{ internalType: 'contract IRewarder', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: '_pid', type: 'uint256' },
          { internalType: 'uint256', name: '_allocPoint', type: 'uint256' },
          { internalType: 'contract IRewarder', name: '_rewarder', type: 'address' },
          { internalType: 'bool', name: 'overwrite', type: 'bool' }
        ],
        name: 'set',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'contract IMigratorChef', name: '_migrator', type: 'address' }],
        name: 'setMigrator',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: '_sushiPerSecond', type: 'uint256' }],
        name: 'setSushiPerSecond',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: rewardPerBlock,
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'totalAllocPoint',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'address', name: 'newOwner', type: 'address' },
          { internalType: 'bool', name: 'direct', type: 'bool' },
          { internalType: 'bool', name: 'renounce', type: 'bool' }
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [{ internalType: 'uint256', name: 'pid', type: 'uint256' }],
        name: 'updatePool',
        outputs: [
          {
            components: [
              { internalType: 'uint128', name: 'accSushiPerShare', type: 'uint128' },
              { internalType: 'uint64', name: 'lastRewardTime', type: 'uint64' },
              { internalType: 'uint64', name: 'allocPoint', type: 'uint64' }
            ],
            internalType: 'struct MiniChefV2.PoolInfo',
            name: 'pool',
            type: 'tuple'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: '', type: 'uint256' },
          { internalType: 'address', name: '', type: 'address' }
        ],
        name: 'userInfo',
        outputs: [
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'int256', name: 'rewardDebt', type: 'int256' }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'pid', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'address', name: 'to', type: 'address' }
        ],
        name: 'withdraw',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'pid', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'address', name: 'to', type: 'address' }
        ],
        name: 'withdrawAndHarvest',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      }
    ]
  } catch (e) {
    console.log(nameFunctions, e)
    return []
  }
}
